// project imports

import { CONFIRM_CODE_VALUE, DATA_PROFILE } from 'store/actions/profileActions';

// action - state management

export const initialState = {
    data_profile: {},
    showing: false,
    confirm_code: ''
};

// ==============================|| PROFILE REDUCER ||============================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_PROFILE:
            return {
                ...state,
                data_profile: action.payload
            };
        case 'SHOWING':
            return {
                ...state,
                showing: action.payload
            };
        case CONFIRM_CODE_VALUE:
            return {
                ...state,
                confirm_code: action.payload
            };

        default:
            return state;
    }
};

export default profileReducer;
