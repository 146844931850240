import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './reducers/authReducer';
import customerReducer from './reducers/customerReducer';
import ipPoolReducer from './reducers/ipPoolReducer';
import nodeReducer from './reducers/nodeReducer';
import osReducer from './reducers/osReducer';
import settingReducer from './reducers/settingReducer';
import pricingReducer from './reducers/pricingReducer';
import locationReducer from './reducers/locationReducer';
import adminReducer from './reducers/adminReducer';
import vmReducer from './reducers/vmReducers';
import profileReducer from './reducers/profileReducer';
import infoReducer from './reducers/infoReducer';
import networkReducer from './reducers/networkReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authReducer: authReducer,
    customerReducer: customerReducer,
    ipPoolReducer: ipPoolReducer,
    nodeReducer: nodeReducer,
    osReducer: osReducer,
    settingReducer: settingReducer,
    pricingReducer: pricingReducer,
    locationReducer: locationReducer,
    adminReducer: adminReducer,
    vmReducer: vmReducer,
    profileReducer: profileReducer,
    infoReducer: infoReducer,
    networkReducer: networkReducer
});

export default reducer;
