import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/navigationScroll';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { IntlProvider } from 'react-intl';
import { ComponentWithEffect } from 'routes/checkUserInfo';
import LookupService from 'service/lookup.service';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const messages = {
    en: {
        'currency.USD': 'United States dollar',
        'currency.IDR': 'Indonesian rupiah',
        'currency.EUR': 'Euro'
    },
    id: {
        'currency.USD': 'Dolar Amerika Serikat',
        'currency.IDR': 'Rupiah Indonesia',
        'currency.EUR': 'Euro'
    }
};

const App = () => {
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const locale = 'id';

    const getPasswordStrength = async () => {
        LookupService.passwordStrength().then((res) => {
            const updatedData = res.data.data.map((item) => ({
                ...item,
                checked: false,
                regex: convertStringToRegex(item.regex)
            }));
            dispatch({ type: 'PASSWORD_STRENGTH', payload: updatedData });
        });
    };

    useEffect(() => {
        getPasswordStrength();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertStringToRegex = (regexString) => {
        regexString = regexString.replace(/^\//, '').replace(/\/$/, '');
        regexString = regexString.replace(/\\\//g, '/');
        return new RegExp(regexString);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ToastContainer />

            <IntlProvider locale={locale} messages={messages[locale]}>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes>{ComponentWithEffect()}</Routes>
                    </NavigationScroll>
                </ThemeProvider>
            </IntlProvider>
        </StyledEngineProvider>
    );
};

export default App;
