import api from './api';
import TokenService from './token.service';

const login = (email, password) => {
    return api
        .post('/backoffice/auth/login', {
            email,
            password
        })
        .then((response) => {
            if (response.data) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
};

const checkAuth = () => {
    return api.get('/backoffice/inf').then((response) => {
        return response.data;
    });
};

const logout = () => {
    return api.post('/backoffice/auth/logout').then((response) => {
        TokenService.removeUser();
        return response.data;
    });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user_info'));
};

const refreshToken = () => {
    return api.post('/backoffice/auth/refresh');
};

//otp
const otpLoginRequest = (credential) => {
    const data = {
        credential: credential,
        type: 'EMAIL',
        request_type: 'LOGIN'
    };
    return api.post('/auth/otp/request', data);
};

const otpLogin = (credential, code) => {
    const data = {
        credential: credential,
        type: 'EMAIL',
        code: code
    };
    return api.post('/auth/otp/login', data);
};

const otpRemainingResend = (credential) => {
    return api.get(`/auth/otp/remaining?credential=${credential}`);
};

//2FA
const verifyTwoFactorCodeAuth = (data) => {
    return api.post('/backoffice/auth/2fa/verify-code', data).then((response) => {
        return response.data;
    });
};

const verifyTwoFactorRecoveryAuth = (data) => {
    return api.post('/backoffice/auth/2fa/verify-recovery', data).then((response) => {
        return response.data;
    });
};

const AuthService = {
    login,
    logout,
    getCurrentUser,
    checkAuth,
    refreshToken,
    otpLoginRequest,
    otpLogin,
    otpRemainingResend,
    verifyTwoFactorCodeAuth,
    verifyTwoFactorRecoveryAuth
};

export default AuthService;
