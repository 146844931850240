import { lazy } from 'react';
import FreeLayout from 'layout/freeLayout';

// project imports
import Loadable from 'ui-component/loadable';

// login option 3 routing

const VMConsole = Loadable(lazy(() => import('views/virtualMachines/instance/console/console')));
const ChangePassword = Loadable(lazy(() => import('views/changePassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Free = {
    path: '/',
    element: <FreeLayout />,
    children: [
        {
            path: 'instance/:name/console',
            element: <VMConsole />,
            exact: true
        },
        {
            path: 'change-password',
            element: <ChangePassword />,
            exact: true
        }
    ]
};

export default Free;
