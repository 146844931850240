import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/loadable';
import MinimalLayout from 'layout/minimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/login3')));
const AuthVerificationOtp = Loadable(lazy(() => import('views/pages/authentication/authentication3/verification')));
const TwoFA = Loadable(lazy(() => import('views/pages/authentication/authentication3/twoFactorAuth')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/verification/otp',
            element: <AuthVerificationOtp />
        },
        {
            path: '/2fa',
            element: <TwoFA />,
            exact: true
        }
    ]
};

export default AuthenticationRoutes;
