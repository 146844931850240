// project imports

import { DETAIL_ANNOUNCEMENT } from 'store/actions/infoAction';

// action - state management

export const initialState = {
    detailAnnouncement: {},
    open: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const infoReducer = (state = initialState, action) => {
    switch (action.type) {
        case DETAIL_ANNOUNCEMENT:
            return {
                ...state,
                detailAnnouncement: action.payload
            };
        case 'OPEN':
            return {
                ...state,
                open: false
            };

        default:
            return state;
    }
};

export default infoReducer;
