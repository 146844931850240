import { lazy } from 'react';

// project imports
import MainLayout from 'layout/mainLayout';
import Loadable from 'ui-component/loadable';
import { Navigate } from 'react-router';

//main menu

const CustomerPrepaid = Loadable(lazy(() => import('views/customer/prepaid')));
const CustomerPending = Loadable(lazy(() => import('views/customer/pendingInvitation')));
const CustomerPostpaid = Loadable(lazy(() => import('views/customer/postpaid')));
const CustomerDetail = Loadable(lazy(() => import('views/customer/detail/index')));
const ComposeEmail = Loadable(lazy(() => import('views/composeEmail')));
const CreateComposeEmail = Loadable(lazy(() => import('views/composeEmail/createComposeEmail/createComposeEmail')));
const DetailComposeEmail = Loadable(lazy(() => import('views/composeEmail/detailComposeEmail')));
const Admin = Loadable(lazy(() => import('views/admin')));
const Helpdesk = Loadable(lazy(() => import('views/helpdesk')));
const HelpdeskDetail = Loadable(lazy(() => import('views/helpdesk/detailTicket')));
const OperatingSystem = Loadable(lazy(() => import('views/system/operatingSystem')));
const Application = Loadable(lazy(() => import('views/system/application')));
const CreateOperatingSystem = Loadable(lazy(() => import('views/system/createOs')));
const DetailOperatingSystem = Loadable(lazy(() => import('views/system/detail')));
const CreateTemplate = Loadable(lazy(() => import('views/system/createTemplate')));
const IpPool = Loadable(lazy(() => import('views/ipPool')));
const CreateIpPool = Loadable(lazy(() => import('views/ipPool/create')));
const UpdateIpPool = Loadable(lazy(() => import('views/ipPool/update')));
const IpPoolDetail = Loadable(lazy(() => import('views/ipPool/detail')));
const CreateIpAddress = Loadable(lazy(() => import('views/ipPool/createIpAddress')));
const Location = Loadable(lazy(() => import('views/location')));
const CreateLocation = Loadable(lazy(() => import('views/location/create')));
const UpdateLocation = Loadable(lazy(() => import('views/location/update')));
const LocationDetail = Loadable(lazy(() => import('views/location/detail')));
const CreateNode = Loadable(lazy(() => import('views/location/node/createNode')));
const PricingComponent = Loadable(lazy(() => import('views/pricing/componentPricing')));
const PricingPackage = Loadable(lazy(() => import('views/pricing/componentPackage')));
const PricingHistoryPrice = Loadable(lazy(() => import('views/pricing/componentHistoryPriceChange')));
const GeneralSetting = Loadable(lazy(() => import('views/settings/generalSetting')));
const TierTemplate = Loadable(lazy(() => import('views/settings/tierTemplate')));
const Rewards = Loadable(lazy(() => import('views/settings/rewards')));
const FirewallSetting = Loadable(lazy(() => import('views/settings/firewallRule')));
const ActivityLogs = Loadable(lazy(() => import('views/log/activityLogs/activityLog')));
const LogTrails = Loadable(lazy(() => import('views/log/logTrails/loginTrails')));
const VirtualMachines = Loadable(lazy(() => import('views/virtualMachines/instance')));
const VirtualMachinesDetail = Loadable(lazy(() => import('views/virtualMachines/instance/detail')));
const Migration = Loadable(lazy(() => import('views/virtualMachines/migration')));
const CreateMigration = Loadable(lazy(() => import('views/virtualMachines/migration/create')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Dashboard = Loadable(lazy(() => import('views/overview')));
const TopupHistory = Loadable(lazy(() => import('views/billing/topupHistory')));
const PostpaidInvoice = Loadable(lazy(() => import('views/billing/postpaidInvoice')));
const BillingReport = Loadable(lazy(() => import('views/billing/reports')));
const BillingReportDetail = Loadable(lazy(() => import('views/billing/reports/detail/detail')));
const Coupon = Loadable(lazy(() => import('views/gift/coupon')));
const RedeemCode = Loadable(lazy(() => import('views/gift/redeemCode')));
const PublicNetwork = Loadable(lazy(() => import('views/network/publicNetwork')));
const PrivateNetwork = Loadable(lazy(() => import('views/network/privateNetwork')));
const BlockStorage = Loadable(lazy(() => import('views/storage/blockStorage')));
const BlockStorageDetail = Loadable(lazy(() => import('views/storage/blockStorage/detail')));
const CreateAnnouncement = Loadable(lazy(() => import('views/info/announcement/createAnnouncement')));
const UpdateAnnoncement = Loadable(lazy(() => import('views/info/announcement/updateAnnouncement')));
const Announcement = Loadable(lazy(() => import('views/info/announcement')));
const CreateReleaseNotes = Loadable(lazy(() => import('views/info/releaseNotes/createReleaseNotes')));
const UpdateReleaseNotes = Loadable(lazy(() => import('views/info/releaseNotes/updateReleaseNotes')));
const ReleaseNotes = Loadable(lazy(() => import('views/info/releaseNotes')));

// ==============================|| MAIN ROUTING ||============================== //
const userRole = JSON.parse(localStorage.getItem('user_info'))?.roles ?? null;
const useCwHelpdesk = process.env.REACT_APP_USE_CW == 'true' ?? false;

function roleAllowed(userRole, allowedRoles) {
    return allowedRoles.includes(userRole);
}

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Dashboard /> : <CustomerPrepaid />
        },
        {
            path: 'admin',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Admin /> : <Navigate to="/" replace />
        },
        {
            path: 'customer/prepaid',
            element: <CustomerPrepaid />
        },
        {
            path: 'customer/pending_invitation',
            element: <CustomerPending />
        },
        {
            path: 'customer/postpaid',
            element: <CustomerPostpaid />
        },
        {
            path: 'customer/detail/:id',
            element: <CustomerDetail />
        },
        {
            path: 'compose-email',
            element: <ComposeEmail />
        },
        {
            path: 'compose-email/create',
            element: <CreateComposeEmail />
        },
        {
            path: 'compose-email/detail/:id',
            element: <DetailComposeEmail />
        },
        {
            path: 'pricing/component',
            element: roleAllowed(userRole, ['Super Admin']) ? <PricingComponent /> : <Navigate to="/" replace />
        },
        {
            path: 'pricing/package',
            element: roleAllowed(userRole, ['Super Admin']) ? <PricingPackage /> : <Navigate to="/" replace />
        },
        {
            path: 'pricing/price',
            element: roleAllowed(userRole, ['Super Admin']) ? <PricingHistoryPrice /> : <Navigate to="/" replace />
        },
        {
            path: 'location',
            element: roleAllowed(userRole, ['Super Admin']) ? <Location /> : <Navigate to="/" replace />
        },
        {
            path: 'location/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateLocation /> : <Navigate to="/" replace />
        },
        {
            path: 'location/update/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <UpdateLocation /> : <Navigate to="/" replace />
        },
        {
            path: 'location/detail/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <LocationDetail /> : <Navigate to="/" replace />
        },
        {
            path: 'location/detail/node/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateNode /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool',
            element: roleAllowed(userRole, ['Super Admin']) ? <IpPool /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateIpPool /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/update/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <UpdateIpPool /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/ip-address/create',
            element: roleAllowed(userRole, ['Super Admin']) ? <CreateIpAddress /> : <Navigate to="/" replace />
        },
        {
            path: 'ip-pool/detail/:id',
            element: roleAllowed(userRole, ['Super Admin']) ? <IpPoolDetail /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/os',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <OperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/app',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Application /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/create',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <CreateOperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/template/create',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <CreateTemplate /> : <Navigate to="/" replace />
        },
        {
            path: 'operating-system/detail/:id',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <DetailOperatingSystem /> : <Navigate to="/" replace />
        },
        {
            path: 'helpdesk',
            element: !useCwHelpdesk ? <Helpdesk /> : <Navigate to="/" replace />
        },
        {
            path: 'helpdesk/:token',
            element: !useCwHelpdesk ? <HelpdeskDetail /> : <Navigate to="/" replace />
        },
        {
            path: 'app-setting/settings',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <GeneralSetting />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'app-setting/tier',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <TierTemplate />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'app-setting/reward',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <Rewards />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'app-setting/firewall_rules',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing']) ? (
                <FirewallSetting />
            ) : (
                <Navigate to="/" replace />
            )
        },

        {
            path: 'log/activity_logs',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <ActivityLogs /> : <Navigate to="/" replace />
        },
        {
            path: 'log/login_trails',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <LogTrails /> : <Navigate to="/" replace />
        },
        {
            path: 'instance',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <VirtualMachines />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'instance/:name',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <VirtualMachinesDetail />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'migration',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <Migration />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'migration/create',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <CreateMigration />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'network/public_network',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <PublicNetwork />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'network/private_network',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <PrivateNetwork />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'gift/coupon',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing', 'Admin Sales']) ? (
                <Coupon />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'gift/redeem_code',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Billing', 'Admin Sales']) ? (
                <RedeemCode />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'dashboard',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin']) ? <Dashboard /> : <Navigate to="/" replace />
        },
        {
            path: 'block-storage',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <BlockStorage />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'block-storage/detail/:id',
            element: roleAllowed(userRole, ['Super Admin', 'Best Admin', 'Admin', 'Admin Support']) ? (
                <BlockStorageDetail />
            ) : (
                <Navigate to="/" replace />
            )
        },
        {
            path: 'billing/topup_history',
            element: <TopupHistory />
        },
        {
            path: 'billing/postpaid_invoice',
            element: <PostpaidInvoice />
        },
        {
            path: 'billing/reports',
            element: <BillingReport />
        },
        {
            path: 'billing/reports/detail/:id',
            element: <BillingReportDetail />
        },
        {
            path: 'info/announcement/create',
            element: <CreateAnnouncement />
        },
        {
            path: 'info/announcement',
            element: <Announcement />
        },
        {
            path: 'info/announcement/update/:id',
            element: <UpdateAnnoncement />
        },
        {
            path: 'info/release_notes/create',
            element: <CreateReleaseNotes />
        },
        {
            path: 'info/release_notes',
            element: <ReleaseNotes />
        },
        {
            path: 'info/release_notes/update/:id',
            element: <UpdateReleaseNotes />
        }
    ]
};

export default MainRoutes;
