export const OPERATING_SYSTEM = 'OPERATING_SYSTEM';
export const LOCATION = 'LOCATION';
export const SERVER_SIZE = 'SERVER_SIZE';
export const HOSTNAME = 'HOSTNAME';
export const USERNAME = 'USERNAME';
export const PASSWORD = 'PASSWORD';
export const SSH_KEY = 'SSH_KEY';
export const SUMMARY = 'SUMMARY';
export const OVERVIEW = 'OVERVIEW';
export const PRICING = 'PRICING';
export const DATA_CONSOLE = 'DATA_CONSOLE';
export const NETWORK = 'NETWORK';
export const ROLLBACK = 'ROLLBACK';
export const TAB_MENU_DETAIL_VM = 'TAB_MENU_DETAIL_VM';
export const MIGRATE_VM = 'MIGRATE_VM';
